<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <!--        <button class="p-link" id="btn_back" @click="backToCustomer">-->
        <!--          <i class="pi pi-angle-left"></i>-->
        <!--        </button>-->
        <div class="my-2">
          <!--          <h3>{{t('siteManagement')}} (Client : {{ customer.name }})</h3>-->
          <h3>{{ t('siteManagement') }}</h3>
        </div>
      </template>
    </Toolbar>
    <div class="col-12">
      <div class="card">
        <Toolbar class="block_add_btn">
          <template v-slot:start>
            <div class="my-2">
              <Button :label="t('addASite')" icon="pi pi-plus" class="add-text p-button-success mr-2"
                      @click="openNewSite"/>
            </div>
          </template>
          <template v-slot:end>
            <Button type="button" icon="pi pi-plus" :label="t('developAll')" @click="expandAll"
                    class="add-text mr-2 inline-block"/>
            <Button type="button" icon="pi pi-minus" :label="t('cutThroughAll')" @click="collapseAll"
                    class="add-text p-button-help"/>
          </template>
        </Toolbar>
        <TreeTable :value="sites" :expandedKeys="expandedKeys" :paginator="true" :rows="30" :lazy="true"
                   :totalRecords="totalItemsSite" @page="onPageSite($event)" responsiveLayout="scroll">
          <Column field="id" header="Id" :expander="true" style="width:10rem"></Column>
          <Column field="code" header="Code" style="width:10rem"></Column>
          <Column field="name" :header="t('wording')" style="width:10rem"></Column>
          <Column field="type" header="Type" style="width:10rem"></Column>

          <Column field="logo" header="Logo" style="width:30rem"></Column>
          <Column :header="t('update')" style="width:10rem;text-align: center;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="button-table mr-2" @click="modalEditSite(slotProps.node)"
                      v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
            </template>
          </Column>
          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>
        </TreeTable>
        <Dialog v-model:visible="addSiteDialog" :style="{width: '900px'}" :header="t('addASite')" :modal="true"
                class="modal_general p-fluid">
          <TabView class="tabs_texte_admin tabview-custom">
            <TabPanel>
              <template #header>
                <span>Structure</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">Code* :</label>
                  <InputText id="name" v-model="site.code" required="true" autofocus
                             :class="{'p-invalid': submitted && !site.code}"/>
                  <small class="p-invalid" v-if="submitted && !site.code">Code is required.</small>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('wording') }}* :</label>
                  <InputText id="name" v-model="site.name" required="true" autofocus
                             :class="{'p-invalid': submitted && !site.name}"/>
                  <small class="p-invalid" v-if="submitted && !site.name">Name is required.</small>
                </div>
              </div>

              <div class="grid formgrid">
                <div class="field-control col">
                  <label for="theme">Type</label>
                  <Dropdown v-model="typeValue" :options="typeValues" optionLabel="name"
                            :placeholder="t('select')"/>
                </div>

              </div>
              <div class="formgrid grid">
                <div class="field col">
                  <label for="titre">{{ t('parentSite') }} :</label>
                  <TreeSelect v-model="selectedsiteParent" :options="sitesParents" selectionMode="single"
                              placeholder="Select Item"/>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('informationsOnTheSite') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('address') }} :</label>
                  <InputText id="name" v-model.trim="site.address" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('additionalAddress') }} :</label>
                  <InputText id="name" v-model.trim="site.additional_address" required="true" autofocus
                  />
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('zipCode') }} : </label>
                  <InputText id="name" v-model.trim="site.zipcode" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('city') }} :</label>
                  <InputText id="name" v-model.trim="site.city" required="true" autofocus
                  />
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('country') }} : </label>
                  <!--                  <InputText id="name" v-model.trim="site.address" required="true" autofocus-->
                  <!--                             />-->

                  <Dropdown v-model="valuecountry" :options="countries" optionLabel="name" required="true"
                            autofocus/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('phoneNumber') }} : </label>
                  <InputText id="name" v-model.trim="site.phone" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">Fax :</label>
                  <InputText id="name" v-model.trim="site.fax" required="true" autofocus
                  />
                </div>
              </div>

              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">Code NAF : </label>
                  <InputText id="name" v-model.trim="site.naf" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('group') }} :</label>
                  <InputText id="name" v-model.trim="site.groupe" required="true" autofocus
                  />
                </div>
              </div>

              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">Code ADR : </label>
                  <InputText id="name" v-model.trim="site.adr" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('LambertCoordinates') }} :</label>
                  <InputText id="name" v-model.trim="site.coordinates_lambert" required="true" autofocus
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('parameters') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <DataTable ref="dt" :value="countries" v-model:selection="selectedCountries" dataKey="id"
                             :paginator="true" :rows="5" :filters="filters"

                             :rowsPerPageOptions="[5,10,25]"

                             responsiveLayout="scroll">
                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column>
                      <template #body="slotProps">
                        {{ slotProps.data.name }}
                      </template>
                    </Column>
                    <Column>
                    </Column>
                  </DataTable>
                </div>
                <div class="field-control col">
                  <DataTable ref="dt" :value="categoriesTheme" v-model:selection="selectedCategoriesTheme" dataKey="id"
                             :paginator="true" :rows="5" :filters="filters"

                             :rowsPerPageOptions="[5,10,25]"

                             responsiveLayout="scroll">
                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column>
                      <template #body="slotProps">
                        {{ slotProps.data.name }}
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('listOfTexts') }}</span>
              </template>
              <DataTable :value="documents['hydra:member']" :lazy="true" :totalRecords="documents['hydra:totalItems']"
                         @page="onPage($event)" :paginator="true" class="p-datatable-gridlines table-filter-texte"
                         :rows="30" dataKey="id" :rowHover="true"
                         v-model:filters="filters1" filterDisplay="menu" :loading="loadingDocuments" :filters="filters1"
                         responsiveLayout="scroll"
                         :globalFilterFields="['titleDescription','dated_at','last_updated_at','theme','domain','subDomain']"
                         @filter="filterDataTable($event)"
              >
                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <template #loading>
                  {{ t('LoadingDataPleaseWait') }}
                </template>
                <!--                <Column header="">-->
                <!--                  <template #body="slotProps">-->
                <!--                    <span class="p-column-title"></span>-->
                <!--                    <img v-if="slotProps.data.country" :src="'demo/images/flags/pi-'+slotProps.data.country.id+'.png'"-->
                <!--                         :alt="slotProps.data.image" width="15"/>-->
                <!--                  </template>-->
                <!--                </Column>-->
                <!--                <Column header="" class="td_flag">-->
                <!--                  <template #body="slotProps">-->
                <!--                    <span class="p-column-title"></span>-->
                <!--                    <img :src="'demo/images/flags/flag-black.png'" :alt="slotProps.data.image" width="15"-->
                <!--                         v-if="slotProps.data.live_status.color=='black'"/>-->
                <!--                    <img :src="'demo/images/flags/flag-red.png'" :alt="slotProps.data.image" width="15"-->
                <!--                         v-if="slotProps.data.live_status.color=='red'"/>-->
                <!--                    <img :src="'demo/images/flags/flag-yellow.png'" :alt="slotProps.data.image" width="15"-->
                <!--                         v-if="slotProps.data.live_status.color=='yellow'"/>-->
                <!--                    <img :src="'demo/images/flags/flag-green.png'" :alt="slotProps.data.image" width="15"-->
                <!--                         v-if="slotProps.data.live_status.color=='green'"/>-->
                <!--                  </template>-->
                <!--                </Column>-->
                <Column field="name" :header="t('text')" style="width:10rem" filterField="titleDescription"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                    <div class="tooltip"
                         v-tooltip.right="'<p><strong> '+  t('text') +' : </strong>'+data.title+'</p><p><strong>Description : </strong>'+
                      data.description+'</p><p><strong>'+t('fieldApplication') +' : </strong>'+data.application_fields+'</p>'">
                      <p>{{ data.title }}</p>
                      <p v-html="data.description"></p>
                    </div>


                  </template>
                  <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Titre"/>
                    <!--                <button>change </button>-->
                  </template>
                </Column>
                <Column :header="t('dateOfText')" style="min-width:8rem" filterField="dated_at"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.dated_at) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
                  </template>
                </Column>
                <Column :header="t('updatedAt')" style="min-width:9rem" filterField="last_updated_at"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text">{{ formatDate(data.last_updated_at) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
                  </template>
                </Column>
                <!--            <Dropdown v-model="categoryValueTheme" :options="categoriesTheme" optionLabel="name"-->
                <!--                      placeholder="Sélectionner" @change="changeTheme"/>-->
                <Column :header="t('theme')" filterField="theme" style="min-width:8rem" :showFilterMatchModes="false">
                  <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.theme"
                      class="image-text">{{ data.theme.name }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="categoriesThemeDoc" placeholder=" Any "
                              class="p-column-filter" :showClear="true">
                      <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                        <span v-else>{{ t('select') }}</span>
                      </template>
                      <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                            slotProps.option.label
                          }}</span>
                      </template>
                    </Dropdown>
                  </template>
                </Column>
                <Column :header="t('domain')" filterField="domain" :showFilterMatchModes="false"
                        :filterMenuStyle="{'width':'14rem'}" style="min-width:8rem">
                  <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.domain"
                      class="image-text">{{ data.domain.name }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="categoriesDomain" placeholder=" Any "
                              class="p-column-filter" :showClear="true">
                      <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                        <span v-else>{{ t('select') }}</span>
                      </template>
                      <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                            slotProps.option.label
                          }}</span>
                      </template>
                    </Dropdown>
                  </template>
                </Column>
                <Column :header="t('subDomain')" filterField="subDomain" style="min-width:8rem"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                    <span v-if="data.sub_domain">{{ data.sub_domain.name }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="categoriesSubDomain" placeholder=" Any "
                              class="p-column-filter" :showClear="true">
                      <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                        <span v-else>{{ t('select') }}</span>
                      </template>
                      <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                            slotProps.option.label
                          }}</span>
                      </template>
                    </Dropdown>
                  </template>
                </Column>


                <!--            <Column :header="t('priority')" filterField="balance" dataType="numeric" style="min-width:7rem">-->
                <!--              <template #body="{data}">-->
                <!--                {{ formatCurrency(data.balance) }}-->
                <!--              </template>-->
                <!--            </Column>-->

                <Column :header="t('applicability')" filterField="subDomain" style="min-width:8rem"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                    <span v-if="data.sub_domain">{{ data.is_applicable }}</span>
                  </template>

                </Column>
                <!--                <Column :header="t('update')" style="min-width:9rem">-->
                <!--                  <template #body="slotProps">-->
                <!--                    <Button icon="pi pi-cog" class="btn_edit button-table mr-2" @click="editDocument(slotProps.data)"-->
                <!--                            v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>-->
                <!--                    <Button icon="pi pi-fw pi-comment" class="btn_comment button-table mr-2"-->
                <!--                            @click="editDocument(slotProps.data)" v-tooltip.top="{value:t('comment'), class: 'editBtn'}"/>-->

                <!--                    &lt;!&ndash;                <Button icon="pi pi-link" class="btn_link button-table mr-2" @click="editDocument(slotProps.data)"/>&ndash;&gt;-->
                <!--                    <a :href="slotProps.data.url" target="_blank">-->
                <!--                      <Button icon="pi pi-link" class="btn_link button-table mr-2"-->
                <!--                              v-tooltip.top="{value:t('link'), class: 'editBtn'}"/>-->
                <!--                    </a>-->

                <!--                    <Button icon="pi pi-file-pdf" class="btn_file_pdf button-table mr-2"-->
                <!--                            @click="editDocument(slotProps.data)" v-tooltip.top="{value:t('synthesis'), class: 'editBtn'}"/>-->
                <!--                    <Button icon="pi pi-file" class="btn_file button-table mr-2" @click="editDocument(slotProps.data)"-->
                <!--                            v-tooltip.top="{value:'Document', class: 'editBtn'}"/>-->
                <!--                  </template>-->
                <!--                </Column>-->
              </DataTable>

            </TabPanel>
          </TabView>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove" @click="hideDialog"/>

            <Button v-if="site.id" :label="t('modify')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="editSite()"/>
            <Button v-else :label="t('next')" icon="pi pi-check" class="p-button-text next_btn" @click="saveSite"/>

          </template>
        </Dialog>
      </div>
    </div>

  </div>
  <Toast/>
</template>

<script>
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {useToast} from "primevue/usetoast";
import {usePrimeVue} from "primevue/config";
import {FilterMatchMode} from "primevue/api";
import moment from "moment-timezone";
import {computed} from "vue";

export default {
  name: "AdminSite",
  data() {
    return {
      idCustomer: '',
      sites: null,
      expandedKeys: {},
      addSiteDialog: false,
      site: {},
      categoriesTheme: [],
      countries: null,
      selectedCountries: [],
      selectedCategoriesTheme: [],
      selectedsiteParent: '',
      customer: '',
      totalItemsSite: 0,
      nbPagesSites: 1,
      sitesParents: [],
      loadingDocuments: false,
      documents: [],
      nbPageDocuments: 1,
      filters1: null,
      id_site: '',
      categoryValueTheme: null,
      categoriesDomain: [],
      categoryValueDomain: null,
      categoriesSubDomain: [],
      categoryValueSubDomain: null,
      categoriesThemeDoc: [],
      valuecountry: '',
      typeValue: '',
      typeValues: [
        {id: 1, name: 'Entity', code: 'entity'},
        {id: 2, name: 'Site', code: 'site'}
      ],
      submitted: false,
    }
  },
  setup() {
    const primevue = usePrimeVue();
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const user = computed(() => {
      return store.getters.currentUser
    });

    return {store, route, toast, primevue, t: primevue.config.StoreTranslation.t, user}
  },
  beforeMount() {
    // this.idCustomer = this.route.params.idCustomer;
    //
    // console.log(this.idCustomer, 'this.idCustomer')
    //
    // this.store.dispatch(Actions.GET_CUSTOMERS_BY_ID, {id: this.idCustomer, 'customHeader': false}).then(data=>{
    //   this.customer =data
    // })


    this.getCategoriesByThemeDoc()

    this.onPageSite(1)
  },
  created() {

    this.initFilters1();

  },
  watch: {
    user() {
      if (this.user.customer) {
        this.idCustomer = this.user.customer.id

      }
    }
  },
  mounted() {
    this.getSitesByCustomer(1)
  },
  methods: {

    initFilters1() {
      this.filters1 = {
        'titleDescription': {value: null, matchMode: FilterMatchMode.EQUALS},
        'dated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
        'last_updated_at': {value: null, matchMode: FilterMatchMode.EQUALS},
        'theme': {value: null, matchMode: FilterMatchMode.EQUALS},
        'domain': {value: null, matchMode: FilterMatchMode.EQUALS},
        'subDomain': {value: null, matchMode: FilterMatchMode.EQUALS},
      }
    },
    clearFilter1() {
      this.initFilters1();
    },
    getSitesByCustomer(page = 1) {
      this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {
        // 'customer': this.idCustomer,
        'page': page,
        'customHeader': true
      }).then(data => {

        // let parentSites = data['hydra:member'][0].childrens
        let sites = data['hydra:member'][0].childrens
        // var formatSelectTree = (obj, indexCh = null) => {
        //   obj.forEach((element, index) => {
        //     let indexF = '0';
        //     if (null === indexCh) {
        //       indexF = index + '';
        //     } else {
        //       indexF = indexCh + '-' + index
        //     }
        //     // console.log('element', element.hasOwnProperty('sites'))
        //
        //     if (Object.prototype.hasOwnProperty.call(element, 'sites')) {
        //       formatSelectTree(element.sites, indexF)
        //     }
        //     Object.assign(element, {"children": element.sites})
        //     //  Object.assign(element, {"label":element.name})
        //     Object.assign(element, {"icon": 'pi pi-angle-right'})
        //     Object.assign(element, {"label": element.name})
        //
        //     Object.assign(element, {"key": element.id})
        //     Object.assign(element, {
        //       "data": {
        //         "id": element.id,
        //         "name": element.name,
        //         "type": element.type,
        //         "code": element.code,
        //       }
        //     })
        //
        //   });
        //   return obj;
        // };

        var formatSelectTree = (obj, indexCh = null) => {
          obj.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens, indexF)

            }
            Object.assign(element, {"children": element.childrens})


            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
              element.name = element.site_name

            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})

            Object.assign(element, {"key": element.id})
            Object.assign(element, {
              "data": {
                "id": element.id,
                "name": element.name,
                "type": element.entity_type,
                "code": element.code,
              }
            })

          });
          return obj;
        };

        // var formatSelectTreeSites = (obj) => {
        //   // obj.forEach((element, index) => {
        //   //   let indexF = '0';
        //   //   if (null === indexCh) {
        //   //     indexF = index + '';
        //   //   } else {
        //   //     indexF = indexCh + '-' + index
        //   //   }
        //   //   if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
        //   //     formatSelectTreeSites(element.childrens, indexF)
        //   //   }
        //   //     console.log(element, 'elements')
        //   //     Object.assign(element, {"children": element.childrens})
        //   //
        //   //
        //   //   if(Object.prototype.hasOwnProperty.call(element, 'site_name') ){
        //   //     Object.assign(element, {"label": element.site_name})
        //   //     element.id = element.site_id
        //   //     element.name = element.site_name
        //   //
        //   //   }
        //   //   else{
        //   //     Object.assign(element, {"label": element.name})
        //   //   }
        //   //
        //   //   Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
        //   //
        //   //   Object.assign(element, {"key": element.id})
        //   //   Object.assign(element, {
        //   //     "data": {
        //   //       "id": element.id,
        //   //       "name": element.name,
        //   //       "type": element.type,
        //   //       "code": element.code,
        //   //     }
        //   //   })
        //   //
        //   // });
        //   return obj;
        // };
        //

        this.sites = formatSelectTree(sites)
        // this.sitesParents = formatSelectTreeSites(parentSites)
        // this.sitesParents = []
        this.totalItemsSite = data['hydra:totalItems']


      })

    },

    getSitesByCustomerParents(page = 1) {
      this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {
        'customer': this.idCustomer,
        'page': page,
        'customHeader': true
      }).then(data => {

        let parentSites = data['hydra:member'][0].childrens
        var formatSelectTreeSites = (obj, indexCh = null) => {
          let elemSites = {}
          obj.forEach((element, index) => {
            if (element.entity_type != 'site') {

              let indexF = '0';
              if (null === indexCh) {
                indexF = index + '';
              } else {
                indexF = indexCh + '-' + index
              }
              if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
                formatSelectTreeSites(element.childrens, indexF)
                elemSites = element
              }


              Object.assign(element, {"children": element.childrens})


              if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
                Object.assign(element, {"label": element.site_name})
                element.id = element.site_id
                element.name = element.site_name

              } else {
                Object.assign(element, {"label": element.name})
              }

              Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})

              Object.assign(element, {"key": element.id})
              Object.assign(element, {
                "data": {
                  "id": element.id,
                  "name": element.name,
                  "type": element.type,
                  "code": element.code,
                }
              })
            } else {
              // element['children'] = []

              delete elemSites['children']
            }
          });
          return obj;
        };

        var filterSites = (parentSites) => {
          parentSites.forEach((row, index) => {
            if (row?.childrens?.length > 0) {
              row[index] = filterSites(row.childrens);
            }
            if (row.entity_type == 'site') {
              delete row[index];
            }

          });
          return parentSites;
        };

        console.log(filterSites(parentSites), 'amine');


        this.sitesParents = formatSelectTreeSites(parentSites)



      })

    },

    expandAll() {
      for (let site of this.sites) {
        this.expandSite(site);
      }

      this.expandedKeys = {...this.expandedKeys};
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    expandSite(site) {
      if (site.children && site.children.length) {
        this.expandedKeys[site.key] = true;

        for (let child of site.children) {
          this.expandSite(child);
        }
      }
    },
    modalEditSite(dataSite) {
      this.id_site = dataSite.id
      this.submitted = false;
      this.addSiteDialog = true;
      let customHeader = false
      this.store.dispatch(Actions.GET_SITES_BY_ID, {id: dataSite.id, customHeader: customHeader}).then(data => {
        this.site.id = dataSite.id
        this.site.code = data.code;
        this.site.name = data.name;
        this.site.address = data.address;
        this.site.additional_address = data.additional_address;
        this.site.zipcode = data.zipcode;
        this.site.address = data.address; /// ville
        this.site.address = data.address; // pays
        this.site.phone = data.phone;
        this.site.fax = data.fax;
        this.site.naf = data.naf;
        this.site.groupe = data.groupe;
        this.site.adr = data.adr;
        this.site.coordinates_lambert = data.coordinates_lambert;
        this.selectedCountries = data.document_countries
        this.selectedCategoriesTheme = data.document_themes
        this.site.city = data.city
        if (data.type == 'entity') {
          this.typeValue = {id: 1, name: 'Entity', code: 'entity'}
        } else {
          this.typeValue = {id: 2, name: 'Site', code: 'site'}
        }
        // this.valuecountry = this.countries.find(item=> data.c)


      })

      this.getCountries();
      this.getCategoriesByTheme();
      this.getSitesByCustomerParents()
      this.getDocumentsBySite(this.nbPageDocuments, this.id_site)
    },
    editSite() {
      this.submitted = true;
      let countriesSelected = []
      let documentsThemeSelected = []
      let id_customer = '/api/customers/' + this.idCustomer

      // let id_parent ='/api/sites/'+Object.keys(this.selectedsiteParent)[0] ;

      this.selectedCountries.forEach(element => {
        countriesSelected.push('/api/countries/' + element.id)
      })
      this.selectedCategoriesTheme.forEach(element => {
        documentsThemeSelected.push('/api/categories/' + element.id)
      })
      this.site.country_id = '/api/countries/' + this.valuecountry.id
      // Object.assign(this.site, {"customer_id":id_customer,'document_countries': countriesSelected, 'document_themes': documentsThemeSelected, 'parent': id_parent})
      Object.assign(this.site, {
        "customer_id": id_customer,
        'document_countries': countriesSelected,
        'document_themes': documentsThemeSelected
      })
      this.site.type = this.typeValue.code
      if (this.site.code && this.site.name) {
        this.store.dispatch(Actions.PUT_SITE, {'site': this.site, 'idSite': this.site.id}, this.site.id).then(() => {
          this.addSiteDialog = false;
          this.toast.add({
            severity: 'success',
            detail: this.t('siteHasSuccessfullyModified'),
            life: 3000
          });
          this.getSitesByCustomer(this.nbPagesSites)
        })
      }
    },

    openNewSite() {
      this.submitted = false;
      this.addSiteDialog = true;
      this.site = {}
      this.getCountries();
      this.getCategoriesByTheme();
    },
    saveSite() {
      this.submitted = true
      let countriesSelected = []
      let documentsThemeSelected = []
      let id_customer = '/api/customers/' + this.idCustomer
      if (Object.keys(this.selectedsiteParent)[0]) {
        this.site.parent = '/api/sites/' + Object.keys(this.selectedsiteParent)[0]
      }
      this.site.country_id = '/api/countries/' + this.valuecountry.id
      this.selectedCountries.forEach(element => {
        countriesSelected.push('/api/countries/' + element.id)
      })
      this.selectedCategoriesTheme.forEach(element => {
        documentsThemeSelected.push('/api/categories/' + element.id)
      })

      Object.assign(this.site, {
        "customer": id_customer,
        'document_countries': countriesSelected,
        'document_themes': documentsThemeSelected
      })
      this.site.type = this.typeValue.code
      if (this.site.code && this.site.name) {
        this.store.dispatch(Actions.ADD_SITE, this.site).then(() => {
          this.toast.add({
            severity: 'success',
            detail: this.t('siteHasSuccessfullyAdded'),
            life: 3000
          });
          this.addSiteDialog = false;
          this.getSitesByCustomer(this.nbPagesSites)
        })
      }
    },
    getCountries() {
      this.countries = []
      this.store.dispatch(Actions.GET_COUNTRIES, {'customHeader': false,}).then(data => {
        this.countries = data
      })
    },
    getCategoriesByTheme() {
      let tabCategoriesTheme = []

      this.store.dispatch(Actions.GET_CATEGORIES, {type: 'THEME', 'customHeader': false,}).then(data => {
        data.forEach(function (element) {
          if (element.type === 'THEME') {
            tabCategoriesTheme.push(element)
          }
        });
        this.categoriesTheme = tabCategoriesTheme;
      })
    },
    hideDialog() {
      this.addSiteDialog = false;
    },
    onPageSite(event) {
      this.nbPagesSites = event.page + 1
      this.getSitesByCustomer(event.page + 1);
    },
    backToCustomer() {
      this.$router.push('/ui-ax/admin-customer');
    },


    getDocumentsBySite(page = 1, id_site) {
      this.loadingDocuments = true
      this.documents = []
      // this.loading[index] = true;
      let idTheme
      let idDomain
      let idSubDomain
      if (this.categoryValueTheme) {
        idTheme = this.categoryValueTheme.id
      }
      if (this.categoryValueDomain) {

        idDomain = this.categoryValueDomain.id
      }
      if (this.categoryValueSubDomain) {
        idSubDomain = this.categoryValueSubDomain.id
      }

      this.store.dispatch(Actions.GET_DOCUMENTS, {
        'idSite': id_site,
        'theme': idTheme,
        'domain': idDomain,
        'sub_domain': idSubDomain,
        'text': this.textAdmin,
        'dated_at': this.dated_at_format,
        'last_updated_at': this.last_updated_at_format,
        'customHeader': true,
        page: page,
      }).then(data => {
        this.documents = data
        // this.loading[index] = false;
        this.loadingDocuments = false

      })
      // setTimeout(() => this.loading[index] = false, 1000);
    },

    onPage(event) {
      this.nbPageDocuments = event.page + 1
      this.getDocumentsBySite(this.nbPageDocuments, this.id_site);
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      });
    },

    filterDataTable(event) {

      // if(event.filters.titleDescription.value){
      this.textAdmin = event.filters.titleDescription.value
      // }

      if (event.filters.dated_at.value) {
        this.dated_at_format = moment(event.filters.dated_at.value).format()
      }
      if (event.filters.last_updated_at.value) {
        this.last_updated_at_format = moment(event.filters.last_updated_at.value).format()
      }
      this.categoryValueTheme = event.filters.theme.value

      if (event.filters.theme.value) {
        this.changeTheme()
      } else if (!event.filters.domain.value) {
        this.getCategoriesByThemeDoc()
      }

      if (this.categoriesDomain.length > 0) {
        if (event.filters.domain.value != this.categoryValueDomain) {
          this.categoryValueDomain = event.filters.domain.value
          this.changeDomain()
          if ((this.categoryValueSubDomain == 2 && !event.filters.subDomain.value) || (this.categoryValueSubDomain == 2 && event.filters.subDomain.value)) {
            event.filters.subDomain.value = null
          }
        }

        this.categoryValueSubDomain = event.filters.subDomain.value
      } else {
        event.filters.domain.value = null
        event.filters.subDomain.value = null
        this.categoryValueDomain = ''
        this.categoryValueSubDomain = null

      }
      this.getDocumentsBySite(this.nbPageDocuments, this.id_site);
      // this.loadDocuments(this.nbPageDocument)

    },

    getCategoriesByThemeDoc() {
      let tabCategoriesTheme = []
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []

      // this.store.dispatch(Actions.GET_CATEGORIES, {type:'THEME','customHeader': false,}).then(data => {
      this.store.dispatch(Actions.GET_CATEGORIES_TREE, {'customHeader': false,}).then(data => {


        var formatSelectTree = (obj, indexCh = null) => {
          obj.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }
            // console.log('element', element.hasOwnProperty('sites'))

            if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
              formatSelectTree(element.categories, indexF)

              if (element.type == 'THEME') {
                tabCategoriesTheme.push(element)
              } else if (element.type == 'DOMAIN') {
                tabCategoriesDomain.push(element)
              }
              // else {
              //   tabCategoriesSubDomain.push(element)
              // }
            } else {
              if (element.type == 'SUB_DOMAIN') {
                tabCategoriesSubDomain.push(element)
              }
            }

            Object.assign(element, {"children": element.categories})
            //  Object.assign(element, {"label":element.name})
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"label": element.name})

            Object.assign(element, {"key": element.id})
            Object.assign(element, {
              "data": {
                "id": element.id,
                "name": element.name,
                "type": element.type,
                "code": element.code,
              }
            })

          });
          return obj;
        };
        this.tabCategories = formatSelectTree(data)


        // data.forEach(function (element) {
        //   if (element.type === 'THEME') {
        //     tabCategoriesTheme.push(element)
        //   }
        //   if (element.type === 'DOMAIN') {
        //     tabCategoriesDomain.push(element)
        //   }
        //   if (element.type === 'SUB_DOMAIN') {
        //     tabCategoriesSubDomain.push(element)
        //   }
        // });
        this.categoriesThemeDoc = tabCategoriesTheme;
        this.categoriesDomain = tabCategoriesDomain;
        this.categoriesSubDomain = tabCategoriesSubDomain;
        this.valuesSubDomain = tabCategoriesSubDomain;
        // this.dropdownValuesTheme = tabCategoriesTheme
      })
    },
    changeTheme() {
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []

      let objTheme = this.categoriesThemeDoc.find(o => o.id === this.categoryValueTheme.id);
      objTheme.categories.forEach(function (element) {
        if (element.type === 'DOMAIN') {
          tabCategoriesDomain.push(element)
        }
        if (element.type === 'SUB_DOMAIN') {
          tabCategoriesSubDomain.push(element)
        }
      })
      this.categoriesDomain = tabCategoriesDomain;
      this.categoriesSubDomain = tabCategoriesSubDomain;

    },
    changeDomain() {
      let tabCategoriesSubDomain = []

      if (this.categoryValueDomain) {
        let objDomain = this.categoriesDomain.find(o => o.id === this.categoryValueDomain.id);
        objDomain.categories.forEach(function (element) {

          if (element.type === 'SUB_DOMAIN') {
            tabCategoriesSubDomain.push(element)
          }
        })
        this.categoryValueSubDomain = 2
        this.categoriesSubDomain = tabCategoriesSubDomain;
      }
    },
  }

}
</script>

<style scoped>

</style>